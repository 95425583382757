import React, {useState, useEffect} from 'react';
import { Box } from '@mui/system';
import {Button, Container, Typography, TextField, Grid, IconButton, Link, Dialog, Card, CardMedia} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LaunchIcon from '@mui/icons-material/Launch';
import TwitterIcon from '@mui/icons-material/Twitter';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function App() {
  const [helpDialogOpen, setHelpDialogOpen] = useState(false)
  const [urlInputBtnDisabled, setUrlInputBtnDisabled] = useState(true)
  const [urlInputValid, setUrlInputValid] = useState(true)
  const [urlInput, setUrlInput] = useState("")
  const [questionInputDisabled, setQuestionInputDisabled] = useState(true)
  const [questionInputBtnDisabled, setQuestionInputBtnDisabled] = useState(true)
  const [goodUrlInput, setGoodUrlInput] = useState("")
  const [processing, setProcessing] = useState(false)
  const [question, setQuestion] = useState("")
  const [questionProcessing, setQuestionProcessing] = useState(false)
  const [goodQuestion, setGoodQuestion] = useState("")
  const [currids, setCurrIds] = useState([])
  const [answer, setAnswer] = useState("")
  const [papers, setPapers] = useState([])

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }

  function onUrlInputChange(value) {
    // if text box is not empty
    if (value != "") {
        // if proper pubmed url
        // need to change it to regex maybe, so I can guarantee it's nonempty after term=
        if (value.includes("pubmed.ncbi.nlm.nih.gov/?term=")) {
          setUrlInputValid(true)
          setUrlInputBtnDisabled(false)
          setUrlInput(value)
        }
        else {
          setUrlInputValid(false)
          setUrlInputBtnDisabled(true)
        }
    }
    // if text box is empty
    else {
      // I want it to look blue if empty... better UX imo
      setUrlInputValid(true)
      setUrlInputBtnDisabled(true)
    }
  }

  // listens for changes in goodUrlInput to call search API
  useEffect(() => {
    async function fetchPapers() {
      setProcessing(true)

      console.log(goodUrlInput)

      const response = await fetch(
        "https://api.xtrct.app/search",
        {
          method:'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            'query':goodUrlInput
          })
        }
      )

      const ids = await response.json()

      setCurrIds(ids)

      setProcessing(false)
      setQuestionInputDisabled(false)

      setQuestion("")
      setGoodQuestion("")
      setQuestionInputBtnDisabled(true)
      setAnswer("")
    }

    if (goodUrlInput != "") {
      fetchPapers()
    }
  }, [goodUrlInput])

  // listens for changes in question and ids
  useEffect(() => {
    async function fetchAnswer() {
      setQuestionProcessing(true)
      setAnswer("")

      const response = await fetch(
        "https://api.xtrct.app/ask",
        {
          method:'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            'question':goodQuestion,
            'context_ids':currids
          })
        }
      )

      const response_json = await response.json()

      setAnswer(response_json['answer'])
      setPapers(response_json['sources'])
      setQuestionProcessing(false)
    }

    if (goodQuestion != "") {
      fetchAnswer()
    }
  }, [goodQuestion, currids])


  function onUrlInputBtnClick() {
    if (urlInput != goodUrlInput) {
      setGoodUrlInput(urlInput)
    }
  }

  function onQuestionInputChange(value) {
    setQuestion(value)
    setQuestionInputBtnDisabled(value=="")
  }

  function onAskBtnClick() {
    setGoodQuestion(question)
  }

  // Makes an accordion from the list of papers returned
  function SimpleAccordion() {
    return (
      answer!="" &&
      <div>
        {papers.map((paper) => (
          <Grid container spacing={0}>
            <Grid item xs={11}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <div>
                  <Typography variant='caption' display="block">
                    {paper['author']} {paper['year']}
                  </Typography>
                  {'\n'}
                  <Typography variant="button" display="block">
                      {paper['title']}
                  </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {paper['abstract']} 
                    {'\n'}
                    <Link href={"https://pubmed.ncbi.nlm.nih.gov/"+paper['source']} target="_blank">
                      Pubmed
                      <LaunchIcon sx={{ fontSize:10}} color="primary"/>
                    </Link>
                    &nbsp;
                    <Link href={"https://www.doi.org/"+paper['doi']} target="_blank">
                      DOI
                      <LaunchIcon sx={{ fontSize:10}} color="primary"/>
                    </Link>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={1}>
              <IconButton sx={{my:1}} onClick = {() => window.open("https://pubmed.ncbi.nlm.nih.gov/"+paper['source'])}>
                <LaunchIcon fontSize="small" color="primary"/>
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </div>
    );
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 2 }}>
        <Typography sx={{ my:0}} variant="h4" align="center" gutterBottom>
          Xtract
        </Typography>
        
        <Box sx={{my:1, display:'flex', justifyContent:'center', gap:"10px"}}>
          <Link 
          component="button" 
          variant="body2" 
          onClick={() => setHelpDialogOpen(true)}>
            Help
          </Link>
          <IconButton onClick = {() => window.open("https://twitter.com/mmaaz_98")}>
                <TwitterIcon fontSize="small" color="primary"/>
          </IconButton>

        </Box>

        <Dialog
        open={helpDialogOpen}
        onClose={() => setHelpDialogOpen(false)}
        >
          <DialogTitle>
            Help
          </DialogTitle>
          <DialogContent>
            <Typography variant="body2">
              Too many Pubmed papers to read? Trying to speed up a literature review? Simply paste your Pubmed search url like the one below:
            </Typography>
            <Typography variant="caption">
              https://pubmed.ncbi.nlm.nih.gov/?term=diabetes
            </Typography>
            <Typography variant="body2">
              Hit Go and wait for Xtract to read all the papers for you.
              Then ask it questions and get answers straight from those papers, complete with sources :)
              (Note: currently it only reads the first 100 papers from your search.)
            </Typography>
            <Box sx={{display:"flex", justifyContent:"center"}}>
              <CardMedia
                component="video"
                image={"demo.mp4"}
                controls
                sx={{ width:"30%" }}
              />
            </Box>
            <Typography variant="caption">
              Made by Maaz Muhammad.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setHelpDialogOpen(false)}>Got it!</Button>
          </DialogActions>
        </Dialog>

        <Box sx={{display:'flex', gap:"10px"}}>
          <TextField
            required
            fullWidth
            label="Paste Pubmed link"
            error={!urlInputValid}
            onChange={(text) => onUrlInputChange(text.target.value)}
          />
          <LoadingButton 
            variant="contained" 
            size="small"
            loading={processing}
            disabled={urlInputBtnDisabled}
            onClick={() => onUrlInputBtnClick()}
          >
            Go!
          </LoadingButton>
        </Box>

        <Box sx={{ m: 4 }} />

        <Box sx={{display:'flex', alignItems:'center', gap:"10px"}}>
          <TextField
            multiline
            rows={2}
            fullWidth
            disabled={questionInputDisabled}
            value={question}
            label={questionInputDisabled ? "Waiting for a valid Pubmed link.." : "Asking from "+goodUrlInput}
            onChange={(text) => onQuestionInputChange(text.target.value)}
          />
          <LoadingButton 
            variant="outline" 
            size="medium"
            disabled={questionInputBtnDisabled}
            loading={questionProcessing}
            onClick={() => onAskBtnClick()}
          >
            Ask
          </LoadingButton>
        </Box>

        <Box sx={{ m: 4 }} />

        <Typography variant="body1" align="left">
          {answer}
        </Typography>

        <Box sx={{ m: 2 }} />

        <SimpleAccordion/>
      </Box>
    </Container>
  );
}
